<template>
  <div class="header">
    <div class="logo">
      <img :src="require('@/assets/logo/logo.png')" />
      <div class="title">电器维修案件管理系统</div>
    </div>
    <div class="menu">
        <span style="font-size: 20px;"><i class="el-icon-s-platform"></i></span>
        <span>欢迎你，{{ $store.state.user.name }}</span>
        <el-dropdown szie="small" @command="handleCommand">
          <span style="font-size: 20px;color:#ffffff;"><i class="el-icon-switch-button"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-user-solid" command="a">个人信息</el-dropdown-item>
            <el-dropdown-item icon="el-icon-warning" command="b">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  methods:{
    handleCommand(command){
      const that = this;
      switch(command){
        case 'a':
          break;
        case 'b':
          that.$store.dispatch("resetAll");
          that.$router.push("/login");
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.header {
  position: fixed;
  width: 100%;
  height: 60px;
  background: #0ba882;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    padding: 0px 15px;
    display: flex;
    align-items: center;
    height: 60px;
    img {
      height: 110%;
    }
    .title {
      font-size: 25px;
      color: #fff;
      padding-left: 15px;
    }
  }
  .menu {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    color: #fff;
    align-items: center;
    span{
        margin: 0px 5px;
    }
  }
}
</style>